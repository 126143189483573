import axios from 'axios';
import React, { useState } from 'react';

function Result(props) {
    const [content, setContent] = useState([]);
    const [iframeSrc, setIframeSrc] = useState('');
    const handleClick = async (event) => {
        const url = event.currentTarget.getAttribute('data-url');
        setIframeSrc(url);
        const filingId = event.currentTarget.getAttribute('data-url');
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     data: {
        //         query: props.query,
        //         filingId: filingId
        //     }
        // };

        // try {
        //     const response = await axios.post('https://dashboard-holders-api-4e81a2861a3c.herokuapp.com/api/global-filings-search-in-filling/', requestOptions.data, requestOptions);
        //     setContent([response.data]);
        // } catch (error) {
        //     console.error('Error fetching data:', error);
        // }
    };

    return (
        <div className='row p-0'>
            <div className='col-md-2 p-0'>
                <ul className='custom-ul'>
                    {props.res.map((data, index) => (
                        <li key={index}>
                            <p data-url={data.url} onClick={handleClick} className='nav-link'>
                                <h3 className='heading-box'>{data.title}</h3>
                                <p className='heading-p'>{data.filedDate}</p>
                                {/* <p className='heading-p'>{data.form}</p>
                                <p className='heading-p'>{data.source}</p>
                                <p className='heading-p'>{data.name}</p>
                                <p className='heading-p'>Page Count: <strong>{data.pageCount}</strong></p> */}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
            {/* <div className='col-md-3 pl-0'>
                {/* <ul> */}
                    {/* {content.map((item, index) => (
                        item.documents[0].excerpts.map((data, index2) => (
                            <div className='seach-snippet' key={index2}>
                                <div dangerouslySetInnerHTML={{__html:data.content}}></div>
                            </div>
                        ))
                    ))} */}
                {/* </ul> */}
            {/* </div> */} 
            <div className='col-md-10 pl-0'>
                {iframeSrc && <iframe className='iframebox' scrolling="no" src={iframeSrc} title="Embedded Content" />}
            </div>
        </div>
    );
}

export default Result;
