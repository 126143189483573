
import React from 'react';
import './App.css';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
// import SearchBar from './component/SearchBar';
import Search from './component/SearchBar';

function App() {
  return (
    <PrimeReactProvider>
            <Search />
    </PrimeReactProvider>
  );
}

export default App;
