import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Result from '../main/Result';
import data from '../static/company.json'
const Search = () => {
  const items = data.map(item => ({
    name: item.company_name,
    ticker: item.ticker // Optionally include other properties if needed
  }));
  const [query, setQuery] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, settoDate] = useState('');
  const [compticker, setTicker] = useState('');
  const [source, setSource] = useState('');
  const [api_source, setApiSource] = useState('');

  const [searchResults, setSearchResults] = useState([]);
  useEffect(()=>{
    // const req = {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     // 'Access-Control-Allow-Origin': '*' // Do not set this here
    //   },
    //   mode: 'no-cors', // This should not be set in Axios
    // };
    // axios.get('https://dashboard-holders-api-4e81a2861a3c.herokuapp.com/api/all-sec-filling-types/',req).
    // then(res =>{
    //   setApiSource(res.data.map(item =>({
    //     name: item.id,
    //     ticker: item.name
    //   })))
    //   console.log('res->', api_source)
    // })
    setApiSource([
      {
        name:'DEF 14A',
        ticker:'DEF 14A'
      },
      {
        name:'8-K',
        ticker:'8-K'
      },
      {
        name:'10-K',
        ticker:'10-K'
      },
  ])
  },[api_source])
  const formatResult = (item) => {
    //console.log(item)
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }
  const formatSourceResult = (item) => {
    //console.log(item)
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }
  const handleAutoComp = async (string)=>{
      setTicker(string.ticker)
  }
  const handleSourceAutoComp = async (string)=>{
    setSource(string.name)
  }
  const handleSearch = async (string) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*' // Do not set this here
    },
    mode: 'no-cors', // This should not be set in Axios
    data: {
      query: query,
      symbols: compticker, //"AAPL",
      fromDate: fromDate, //"2021-01-01",
      toDate: toDate, //"2023-12-12",
      sources: "SEC",
      forms:  source, //"DEF 14A"
    }
  };

axios.post('https://dashboard-holders-api-4e81a2861a3c.herokuapp.com/api/global-filings-search/', requestOptions.data, requestOptions)
  .then(response => {
    setSearchResults(response.data.filings)
    console.log(searchResults);
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  });

  };


  return (
    <>
    <div className='container-fluid custom-header'>
      <div className='row'>
          <div className='col-md-4 m-0 p-2'>
          <ReactSearchAutocomplete
            items={items}
            showIcon={false}
            maxResults={100}
            styling={{borderRadius:".25rem",height:"calc(1.5em + .75rem + 2px)"}}
            placeholder='Search Ticker'
            onSelect={handleAutoComp}
            formatResult={formatResult}
          /> 
          </div>
          <div className='col-md-2 m-0 p-2'>
            <input 
              type="date"
              value={fromDate}
              onChange={(e)=> setFromDate(e.target.value)}
              className='form-control'
              placeholder='Choose Date'
            />
          </div>
          <div className='col-md-2 m-0 p-2'>
            <input 
              type="date"
              value={toDate}
              onChange={(e)=> settoDate(e.target.value)}
              className='form-control'
              placeholder='Choose Date'
            />
          </div>
          <div className='col-md-2 m-0 p-2'>
            <select className='form-control' value={source}
              onChange={(e)=> setSource(e.target.value)} >
                <option value="">Select All</option>
                <option value="DEF 14A">DEF 14A</option>
                <option value="8-k">8-k</option>
                <option value="10-k">10-k</option>
            </select>
            {/* <ReactSearchAutocomplete
              items={api_source}
              showIcon={false}
              maxResults={100}
              styling={{borderRadius:".25rem",height:"calc(1.5em + .75rem + 2px)"}}
              placeholder='Search Filings'
              onSelect={handleSourceAutoComp}
              formatResult={formatSourceResult}
              
            />  */}
          </div>
          {/* <div className='col-md-2 m-0 p-2'>
            <input
              type="text"
              className='form-control'
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search..."
            />
            
          </div> */}
            {/* */}
          {/* </div> */}
            <div className='col-md-2 m-0 p-2'>  
                <button onClick={handleSearch} className='btn w-50 btn-primary'>Search</button>
            </div>
      </div>
    </div>
      <div className='container-fluid'>
          <Result res={searchResults} query={query}></Result>
      </div>
            
          
      </>
  );
};

export default Search;
